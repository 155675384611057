import './App.css';
import React, { Component }  from 'react';
//import Home from './pages/home/home';
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import Profile from "./pages/profile/profile";
import Boards from "./pages/boards/boards";
import Finas from "./pages/boards/finas";
import Anodize from "./pages/boards/anodize";
import Debur from "./pages/boards/debur";
import Hydro from "./pages/boards/hydro";
import Punch from "./pages/boards/punch";
import Silk from "./pages/boards/silk";
import Spotwelding from "./pages/boards/spotwelding";
import Timesaver from "./pages/boards/timesaver";
import QC from "./pages/boards/qc";
import FinalQC from "./pages/boards/finalqc";
import Kits from "./pages/boards/kits";
import Silicone from "./pages/boards/silicone";
import ShipAll from "./pages/reports/shipping/all";
import ShipBoss from "./pages/reports/shipping/boss";
import ShipContainers from "./pages/reports/shipping/containers";
import ShipDepuy from "./pages/reports/shipping/depuy";
import ShipDepuyMedtronic from "./pages/reports/shipping/depuymedtronic";
import ShipKarlstorz from "./pages/reports/shipping/karlstorz";
import ShipMedtronic from "./pages/reports/shipping/medtronic";
import ShipMedtronicDone from "./pages/reports/shipping/medtronicdone";
import ShipKarlStorzDone from "./pages/reports/shipping/karlstorzdone";
import ShipNonoem from "./pages/reports/shipping/nonoem";
import ShipNsi from "./pages/reports/shipping/nsi";
import ShipOem from "./pages/reports/shipping/oem";
import ShipOlympus from "./pages/reports/shipping/olympus";
import ShipOnhand from "./pages/reports/shipping/onhand";
import ShipSolutions from "./pages/reports/shipping/solutions";
import ShipTeleflex from "./pages/reports/shipping/teleflex";
import ShipYnumbers from "./pages/reports/shipping/ynumbers";
import ShipDelivery from "./pages/reports/shipping/delivery";
import ShipComplete from "./pages/reports/shipping/complete";
import ShipInventory from "./pages/reports/shipping/inventory";
import ShipInventoryNow from "./pages/reports/shipping/inventorynow";
import ShipLocation from "./pages/reports/shipping/location";
import ShipReceiving from "./pages/reports/shipping/receiving";

import AutoBuy from "./pages/reports/autobuy/autobuy";
import CanadaSales from "./pages/reports/canadasales/canadasales";
import ConsumablesContainerSales from "./pages/reports/consumablescontainersales/consumablescontainersales";
//import ComplaintSummary from "./pages/reports/complaintsummary/complaintsummary";
import CustomerPurchase from "./pages/reports/customer/customerpurchase";
import Distributor from "./pages/reports/distributor/distributor";
import ECommerce from "./pages/reports/ecommerce/ecommerce";
import EmailAnalytics from "./pages/reports/emailanalytics/emailanalytics";
import Maintenance from "./pages/reports/maintenance/maintenance";
import InventoryData from "./pages/reports/inventory/inventorydata";
import LatinSales from "./pages/reports/latinsales/latinsales";
import Manufacturing from "./pages/reports/manufacturing/manufacturing";
//import Solutions from "./pages/reports/solutions/solutions";
import MasterCross from "./pages/reports/mastercross/mastercross";
import MAXSales from "./pages/reports/maxsales/maxsales";
import NegativeNumbers from "./pages/reports/negativenumbers/negativenumbers";
import OrderLeadTime from "./pages/reports/orderleadtime/orderleadtime";
//import ProductSales from "./pages/reports/productsales/productsales";
import SafetyStock from "./pages/reports/safetystock/safetystock";
import SalesAnalytics from "./pages/reports/salesanalytics/salesanalytics";
import SummaryReport from "./pages/reports/summaryreport/summaryreport";
import TerritoryPenetration from "./pages/reports/territorypenetration/territoryPenetration";
import WebTraffic from "./pages/reports/webtraffic/webtraffic";
import JapanSales from "./pages/reports/japansales/japansales";
import ChinaSales from "./pages/reports/chinasales/chinasales";
import SouthKoreaSales from "./pages/reports/southkoreasales/southkoreasales";
import TaiwanSales from "./pages/reports/taiwansales/taiwansales";
import USSales from "./pages/reports/ussales/ussales";
import IrelandSales from "./pages/reports/irelandsales/irelandsales";
import Sales2024 from "./pages/reports/sales2024/sales2024";
import NovaplusSales from "./pages/reports/novaplussales/novaplussales";
import MfgComp from "./pages/reports/mfgcomp/mfgcomp";
import DomesticSales from "./pages/reports/domesticsales/domesticsales";


import AnodizeLog from "./pages/anodize/log";
import AnodizeParts from "./pages/anodize/parts";
import StageTwo from "./pages/anodize/stagetwo";
import QCReview from "./pages/anodize/qcreview";
import Modify from "./pages/anodize/modify";
import QCEdit from "./pages/anodize/qcedit";
import Drawings from "./pages/production/drawings";
import SetupSheets from "./pages/production/setupsheets";
import Partlot from "./pages/production/partlots";
import Labels from "./pages/production/labels";
import Picklist from "./pages/production/picklist";
import Anodizing from "./pages/production/anodize";
import Passivation from "./pages/production/passivation";
import WCA from "./pages/production/wca";
import BlankCofc from "./pages/cofc/blank";
import PurchaseProd from "./pages/exports/purchaseprod";
import SalesOrders from "./pages/exports/salesorders";
import Eyepro from "./pages/exports/eyepro";
import HPG from "./pages/exports/healthtrust";
import Novation from "./pages/exports/novation";
import Premier from "./pages/exports/premier";
import VA from "./pages/exports/va";
import Vizient from "./pages/exports/vizient";
import Quotes from "./pages/exports/quotes";
import QuickBooks from "./pages/exports/quickbooks";
import GeneralAdmin from "./pages/exports/general";
import Whiteboard from "./pages/whiteboard/whiteboard";
//import Signup from "./pages/signup/signup";
import {BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams} from "react-router-dom";

function App() {
  return (
    <Router>
      <Route path="/" component={Login} exact={true}/>
      <Route path="/dashboard" component={Dashboard} exact={true}/>
      <Route path="/profile" component={Profile} exact={true}/>
      <Route path="/boards" component={Boards} exact={true}/>
      <Route path="/finas" component={Finas} exact={true}/>
      <Route path="/anodize" component={Anodize} exact={true}/>
      <Route path="/debur" component={Debur} exact={true}/>
      <Route path="/hydro" component={Hydro} exact={true}/>
      <Route path="/punch" component={Punch} exact={true}/>
      <Route path="/silk" component={Silk} exact={true}/>
      <Route path="/spotwelding" component={Spotwelding} exact={true}/>
      <Route path="/timesaver" component={Timesaver} exact={true}/>
      <Route path="/qc" component={QC} exact={true}/>
      <Route path="/finalqc" component={FinalQC} exact={true}/>
      <Route path="/kits" component={Kits} exact={true}/>
      <Route path="/silicone" component={Silicone} exact={true}/>
      <Route path="/drawings" component={Drawings} exact={true}/>
      <Route path="/setupsheets" component={SetupSheets} exact={true}/>
      <Route path="/partlots" component={Partlot} exact={true}/>
      <Route path="/labels" component={Labels} exact={true}/>
      <Route path="/picklist" component={Picklist} exact={true}/>
      <Route path="/anodizing" component={Anodizing} exact={true}/>
      <Route path="/passivation" component={Passivation} exact={true}/>
      <Route path="/purchase" component={PurchaseProd} exact={true}/>
      <Route path="/salesorders" component={SalesOrders} exact={true}/>
      <Route path="/eyepro" component={Eyepro} exact={true}/>
      <Route path="/hpg" component={HPG} exact={true}/>
      <Route path="/novation" component={Novation} exact={true}/>
      <Route path="/premier" component={Premier} exact={true}/>
      <Route path="/va" component={VA} exact={true}/>
      <Route path="/vizient" component={Vizient} exact={true}/>
      <Route path="/quotes" component={Quotes} exact={true}/>
      <Route path="/quickbooks" component={QuickBooks} exact={true}/>
      <Route path="/generaladmin" component={GeneralAdmin} exact={true}/>
      <Route path="/anodizelog" component={AnodizeLog} exact={true}/>
      <Route path="/anodizeparts" component={AnodizeParts} exact={true}/>
      <Route path="/stagetwo" render={(props) => <StageTwo {...props}/>}/>
      <Route path="/qcreview" render={(props) => <QCReview {...props}/>}/>
      <Route path="/qcedit" render={(props) => <QCEdit {...props}/>}/>
      <Route path="/modify" render={(props) => <Modify {...props}/>}/>
      <Route path="/shippingpriorityall" component={ShipAll} exact={true}/>
      <Route path="/shippingpriorityboss" component={ShipBoss} exact={true}/>
      <Route path="/shippingprioritycontainers" component={ShipContainers} exact={true}/>
      <Route path="/shippingprioritydepuy" component={ShipDepuy} exact={true}/>
      <Route path="/shippingprioritydepuymedtronic" component={ShipDepuyMedtronic} exact={true}/>
      <Route path="/shippingprioritykarlstorz" component={ShipKarlstorz} exact={true}/>
      <Route path="/shippingprioritymedtronic" component={ShipMedtronic} exact={true}/>
      <Route path="/shippingprioritymedtronicdone" component={ShipMedtronicDone} exact={true}/>
      <Route path="/shippingprioritykarlstorzdone" component={ShipKarlStorzDone} exact={true}/>
      <Route path="/shippingprioritynonoem" component={ShipNonoem} exact={true}/>
      <Route path="/shippingprioritynsi" component={ShipNsi} exact={true}/>
      <Route path="/shippingpriorityoem" component={ShipOem} exact={true}/>
      <Route path="/shippingpriorityolympus" component={ShipOlympus} exact={true}/>
      <Route path="/shippingpriorityonhand" component={ShipOnhand} exact={true}/>
      <Route path="/shippingprioritysolutions" component={ShipSolutions} exact={true}/>
      <Route path="/shippingpriorityteleflex" component={ShipTeleflex} exact={true}/>
      <Route path="/shippingpriorityynumbers" component={ShipYnumbers} exact={true}/>
      <Route path="/shippingprioritydelivery" component={ShipDelivery} exact={true}/>
      <Route path="/shippingprioritycomplete" component={ShipComplete} exact={true}/>
      <Route path="/inventory" component={ShipInventory} exact={true}/>
      <Route path="/dailyinventory" component={ShipInventoryNow} exact={true}/>
      <Route path="/location" component={ShipLocation} exact={true}/>
      <Route path="/receiving" component={ShipReceiving} exact={true}/>
      
      <Route path="/cofc" component={BlankCofc} exact={true}/>
      <Route path="/whiteboard" component={Whiteboard} exact={true}/>
      <Route path="/wca" component={WCA} exact={true}/>


      <Route path="/autobuy" component={AutoBuy} exact={true}/>
      <Route path="/consumablescontainersales" component={ConsumablesContainerSales} exact={true}/>
      <Route path="/customerpurchase" component={CustomerPurchase} exact={true}/>
      <Route path="/distributor" component={Distributor} exact={true}/>
      <Route path="/ecommerce" component={ECommerce} exact={true}/>
      <Route path="/emailanalytics" component={EmailAnalytics} exact={true}/>
      <Route path="/maintenance" component={Maintenance} exact={true}/>
      <Route path="/inventorydata" component={InventoryData} exact={true}/>
      <Route path="/manufacturing" component={Manufacturing} exact={true}/>
      <Route path="/mastercross" component={MasterCross} exact={true}/>
      <Route path="/maxsales" component={MAXSales} exact={true}/>
      <Route path="/negativenumbers" component={NegativeNumbers} exact={true}/>
      <Route path="/novaplussales" component={NovaplusSales} exact={true}/>
      <Route path="/orderleadtime" component={OrderLeadTime} exact={true}/>
      <Route path="/safetystock" component={SafetyStock} exact={true}/>
      <Route path="/salesanalytics" component={SalesAnalytics} exact={true}/>
      <Route path="/summaryreport" component={SummaryReport} exact={true}/>
      <Route path="/territorypenetration" component={TerritoryPenetration} exact={true}/>
      <Route path="/webtraffic" component={WebTraffic} exact={true}/>
      <Route path="/canadasales" component={CanadaSales} exact={true}/>
      <Route path="/latinsales" component={LatinSales} exact={true}/>
      <Route path="/japansales" component={JapanSales} exact={true}/>
      <Route path="/chinasales" component={ChinaSales} exact={true}/>
      <Route path="/southkoreasales" component={SouthKoreaSales} exact={true}/>
      <Route path="/taiwansales" component={TaiwanSales} exact={true}/>
      <Route path="/ussales" component={USSales} exact={true}/>
      <Route path="/irelandsales" component={IrelandSales} exact={true}/>
      <Route path="/sales2024" component={Sales2024} exact={true}/>
      <Route path="/mfgcomp" component={MfgComp} exact={true}/>
      <Route path="/domesticsales" component={DomesticSales} exact={true}/>
    </Router>
  );
}

export default App;
